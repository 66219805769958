// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/src/Core__Float.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "../Utilities/LoggerUtils.bs.js";
import * as CardThemeType$OrcaPaymentPage from "../Types/CardThemeType.bs.js";

function logFileToObj(logFile) {
  var match = logFile.logType;
  var tmp;
  switch (match) {
    case "DEBUG" :
        tmp = "DEBUG";
        break;
    case "INFO" :
        tmp = "INFO";
        break;
    case "ERROR" :
        tmp = "ERROR";
        break;
    case "WARNING" :
        tmp = "WARNING";
        break;
    case "SILENT" :
        tmp = "SILENT";
        break;
    
  }
  var match$1 = logFile.category;
  var tmp$1;
  switch (match$1) {
    case "API" :
        tmp$1 = "API";
        break;
    case "USER_ERROR" :
        tmp$1 = "USER_ERROR";
        break;
    case "USER_EVENT" :
        tmp$1 = "USER_EVENT";
        break;
    case "MERCHANT_EVENT" :
        tmp$1 = "MERCHANT_EVENT";
        break;
    
  }
  return Object.fromEntries([
              [
                "timestamp",
                logFile.timestamp
              ],
              [
                "log_type",
                tmp
              ],
              [
                "component",
                "WEB"
              ],
              [
                "category",
                tmp$1
              ],
              [
                "source",
                LoggerUtils$OrcaPaymentPage.convertToScreamingSnakeCase(logFile.source)
              ],
              [
                "version",
                logFile.version
              ],
              [
                "value",
                logFile.value
              ],
              [
                "internal_metadata",
                logFile.internalMetadata
              ],
              [
                "session_id",
                logFile.sessionId
              ],
              [
                "merchant_id",
                logFile.merchantId
              ],
              [
                "payment_id",
                logFile.paymentId
              ],
              [
                "app_id",
                logFile.appId
              ],
              [
                "platform",
                LoggerUtils$OrcaPaymentPage.convertToScreamingSnakeCase(logFile.platform)
              ],
              [
                "user_agent",
                logFile.userAgent
              ],
              [
                "event_name",
                LoggerUtils$OrcaPaymentPage.eventNameToStrMapper(logFile.eventName)
              ],
              [
                "browser_name",
                LoggerUtils$OrcaPaymentPage.convertToScreamingSnakeCase(logFile.browserName)
              ],
              [
                "browser_version",
                logFile.browserVersion
              ],
              [
                "latency",
                logFile.latency
              ],
              [
                "first_event",
                logFile.firstEvent ? "true" : "false"
              ],
              [
                "payment_method",
                LoggerUtils$OrcaPaymentPage.convertToScreamingSnakeCase(logFile.paymentMethod)
              ]
            ]);
}

function getRefFromOption(val) {
  var innerValue = Core__Option.getOr(val, "");
  return {
          contents: innerValue
        };
}

function getSourceString(source) {
  if (typeof source !== "object") {
    if (source === "Loader") {
      return "hyper_loader";
    } else {
      return "headless";
    }
  }
  var formattedPaymentMode = LoggerUtils$OrcaPaymentPage.toSnakeCaseWithSeparator(CardThemeType$OrcaPaymentPage.getPaymentModeToStrMapper(source._0), "_");
  return "hyper" + formattedPaymentMode;
}

function findVersion(re, content) {
  var result = re.exec(content);
  if (result !== null) {
    return result;
  } else {
    return [];
  }
}

function browserDetect(content) {
  var patterns = [
    [
      "Instagram",
      /Instagram\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Instagram"
    ],
    [
      "FBAV",
      /FBAV\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Facebook"
    ],
    [
      "Twitter",
      /iPhone\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Twitter"
    ],
    [
      "LinkedIn",
      /LinkedInApp\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "LinkedIn"
    ],
    [
      "Edg",
      /Edg\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Microsoft Edge"
    ],
    [
      "Chrome",
      /Chrome\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Chrome"
    ],
    [
      "Safari",
      /Safari\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Safari"
    ],
    [
      "Opera",
      /Opera\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Opera"
    ],
    [
      "Firefox",
      /Firefox\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Firefox"
    ],
    [
      "fxios",
      /fxios\/([\d]+\.[\w]?\.?[\w]+)/ig,
      "Firefox"
    ]
  ];
  var result = Belt_Array.get(Belt_Array.keepMap(patterns, (function (param) {
              if (!new RegExp(param[0]).test(content)) {
                return ;
              }
              var v = Core__Option.getOr(findVersion(param[1], content)[1], null);
              var version = (v == null) ? "" : v;
              return param[2] + "-" + version;
            })), 0);
  if (result !== undefined) {
    return result;
  } else {
    return "Others-0";
  }
}

var arrayOfNameAndVersion = browserDetect(window.navigator.userAgent).split("-");

function make(sessionId, source, clientSecret, merchantId, metadata, ephemeralKey) {
  var match = loggingLevel;
  var loggingLevel$1;
  switch (match) {
    case "DEBUG" :
        loggingLevel$1 = "DEBUG";
        break;
    case "ERROR" :
        loggingLevel$1 = "ERROR";
        break;
    case "INFO" :
        loggingLevel$1 = "INFO";
        break;
    case "WARNING" :
        loggingLevel$1 = "WARNING";
        break;
    default:
      loggingLevel$1 = "SILENT";
  }
  var mainLogFile = [];
  var sessionId$1 = getRefFromOption(sessionId);
  var setSessionId = function (value) {
    sessionId$1.contents = value;
  };
  var sourceString = getSourceString(source);
  var events = {
    contents: {}
  };
  var eventsCounter = {
    contents: {}
  };
  var timeOut = {
    contents: undefined
  };
  var merchantId$1 = getRefFromOption(merchantId);
  var setMerchantId = function (value) {
    merchantId$1.contents = value;
  };
  var metadata$1 = {
    contents: Core__Option.getOr(metadata, null)
  };
  var setMetadata = function (value) {
    metadata$1.contents = value;
  };
  var calculateAndUpdateCounterHook = function (eventName) {
    var num = eventsCounter.contents[eventName];
    var updatedCounter = num !== undefined ? num + 1 | 0 : 1;
    eventsCounter.contents[eventName] = updatedCounter;
    return updatedCounter;
  };
  var conditionalLogPush = function (log) {
    var maxLogsPushedPerEventName$1 = maxLogsPushedPerEventName;
    var match = log.eventName;
    var conditionalEventName;
    conditionalEventName = match === "INPUT_FIELD_CHANGED" ? log.value : "";
    var eventName = LoggerUtils$OrcaPaymentPage.eventNameToStrMapper(log.eventName) + conditionalEventName;
    var counter = calculateAndUpdateCounterHook(eventName);
    if (!(enableLogging && counter <= maxLogsPushedPerEventName$1)) {
      return ;
    }
    switch (loggingLevel$1) {
      case "DEBUG" :
          ((function (__x) {
                  mainLogFile.push(__x);
                })(log));
          return ;
      case "INFO" :
          if ([
                "INFO",
                "WARNING",
                "ERROR"
              ].includes(log.logType)) {
            ((function (__x) {
                    mainLogFile.push(__x);
                  })(log));
            return ;
          } else {
            return ;
          }
      case "ERROR" :
          if (["ERROR"].includes(log.logType)) {
            ((function (__x) {
                    mainLogFile.push(__x);
                  })(log));
            return ;
          } else {
            return ;
          }
      case "WARNING" :
          if ([
                "WARNING",
                "ERROR"
              ].includes(log.logType)) {
            ((function (__x) {
                    mainLogFile.push(__x);
                  })(log));
            return ;
          } else {
            return ;
          }
      case "SILENT" :
          return ;
      
    }
  };
  var beaconApiCall = function (data) {
    if (data.length <= 0) {
      return ;
    }
    var logData = JSON.stringify(data.map(logFileToObj));
    navigator.sendBeacon(logEndpoint, logData);
  };
  var clientSecret$1 = getRefFromOption(clientSecret);
  var ephemeralKey$1 = getRefFromOption(ephemeralKey);
  var setClientSecret = function (value) {
    clientSecret$1.contents = value;
  };
  var setEphemeralKey = function (value) {
    ephemeralKey$1.contents = value;
  };
  var sourceRef = {
    contents: getSourceString(source)
  };
  var setSource = function (value) {
    sourceRef.contents = value;
  };
  var sendLogs = function () {
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      timeOut.contents = Caml_option.some(setTimeout((function () {
                  sendLogs();
                }), 20000));
    } else {
      timeOut.contents = Caml_option.some(setTimeout((function () {
                  sendLogs();
                }), 20000));
    }
    beaconApiCall(mainLogFile);
    var len = mainLogFile.length;
    for(var _for = 0; _for < len; ++_for){
      mainLogFile.pop();
    }
  };
  var checkForPriorityEvents = function (arrayOfLogs) {
    var priorityEventNames = [
      "APP_RENDERED",
      "ORCA_ELEMENTS_CALLED",
      "PAYMENT_DATA_FILLED",
      "PAYMENT_ATTEMPT",
      "CONFIRM_CALL",
      "AUTHENTICATION_CALL",
      "THREE_DS_METHOD_RESULT",
      "SDK_CRASH",
      "REDIRECTING_USER",
      "DISPLAY_BANK_TRANSFER_INFO_PAGE",
      "DISPLAY_QR_CODE_INFO_PAGE",
      "DISPLAY_VOUCHER",
      "LOADER_CHANGED",
      "PAYMENT_METHODS_CALL",
      "PAYMENT_METHOD_CHANGED",
      "SESSIONS_CALL",
      "RETRIEVE_CALL",
      "DISPLAY_THREE_DS_SDK",
      "APPLE_PAY_FLOW",
      "PLAID_SDK"
    ];
    if (Core__Option.isSome(arrayOfLogs.find(function (log) {
                return [
                          "ERROR",
                          "DEBUG"
                        ].includes(log.logType) ? true : (
                          priorityEventNames.includes(log.eventName) ? log.firstEvent : false
                        );
              }))) {
      return true;
    } else {
      return arrayOfLogs.length > 8;
    }
  };
  var checkLogSizeAndSendData = function () {
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      timeOut.contents = Caml_option.some(setTimeout((function () {
                  sendLogs();
                }), 20000));
    } else {
      timeOut.contents = Caml_option.some(setTimeout((function () {
                  sendLogs();
                }), 20000));
    }
    if (checkForPriorityEvents(mainLogFile)) {
      return sendLogs();
    }
    
  };
  var calculateLatencyHook = function (eventName, apiLogTypeOpt) {
    var apiLogType = apiLogTypeOpt !== undefined ? apiLogTypeOpt : "Method";
    var currentTimestamp = Date.now();
    var latency;
    if (eventName === "PAYMENT_ATTEMPT") {
      var appRenderedTimestamp = events.contents[LoggerUtils$OrcaPaymentPage.eventNameToStrMapper("APP_RENDERED")];
      latency = appRenderedTimestamp !== undefined ? currentTimestamp - appRenderedTimestamp : 0;
    } else {
      var logRequestTimestamp = events.contents[LoggerUtils$OrcaPaymentPage.eventNameToStrMapper(eventName) + "_INIT"];
      latency = logRequestTimestamp !== undefined ? (
          apiLogType === "Request" ? 0 : currentTimestamp - logRequestTimestamp
        ) : 0;
    }
    if (latency > 0) {
      return latency.toString();
    } else {
      return "";
    }
  };
  var checkAndPushMissedEvents = function (eventName, paymentMethod) {
    if (eventName !== "PAYMENT_ATTEMPT") {
      return ;
    }
    var paymentMethodChangedEventStr = LoggerUtils$OrcaPaymentPage.eventNameToStrMapper("PAYMENT_METHOD_CHANGED");
    var paymentDataFilledEventStr = LoggerUtils$OrcaPaymentPage.eventNameToStrMapper("PAYMENT_DATA_FILLED");
    var localTimestamp = Date.now().toString();
    var localTimestampFloat = Core__Option.getOr(Core__Float.fromString(localTimestamp), Date.now());
    var paymentMethodChangedEvent = Core__Option.isNone(events.contents[paymentMethodChangedEventStr]);
    var paymentDataFilledEvent = Core__Option.isNone(events.contents[paymentDataFilledEventStr]);
    if (paymentMethodChangedEvent) {
      conditionalLogPush({
            timestamp: localTimestamp,
            logType: "INFO",
            category: "USER_EVENT",
            source: sourceString,
            version: repoVersion,
            value: "",
            internalMetadata: "",
            sessionId: sessionId$1.contents,
            merchantId: merchantId$1.contents,
            paymentId: LoggerUtils$OrcaPaymentPage.getPaymentId(clientSecret$1.contents),
            appId: "",
            platform: window.navigator.platform,
            browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
            browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
            userAgent: window.navigator.userAgent,
            eventName: "PAYMENT_METHOD_CHANGED",
            latency: "",
            firstEvent: true,
            paymentMethod: paymentMethod,
            metadata: metadata$1.contents,
            ephemeralKey: ephemeralKey$1.contents
          });
      events.contents[paymentMethodChangedEventStr] = localTimestampFloat;
    }
    if (paymentDataFilledEvent) {
      conditionalLogPush({
            timestamp: localTimestamp,
            logType: "INFO",
            category: "USER_EVENT",
            source: sourceString,
            version: repoVersion,
            value: "",
            internalMetadata: "",
            sessionId: sessionId$1.contents,
            merchantId: merchantId$1.contents,
            paymentId: LoggerUtils$OrcaPaymentPage.getPaymentId(clientSecret$1.contents),
            appId: "",
            platform: window.navigator.platform,
            browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
            browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
            userAgent: window.navigator.userAgent,
            eventName: "PAYMENT_DATA_FILLED",
            latency: "",
            firstEvent: true,
            paymentMethod: paymentMethod,
            metadata: metadata$1.contents,
            ephemeralKey: ephemeralKey$1.contents
          });
      events.contents[paymentDataFilledEventStr] = localTimestampFloat;
      return ;
    }
    
  };
  var setLogInfo = function (value, internalMetadataOpt, eventName, timestamp, latency, logTypeOpt, logCategoryOpt, paymentMethodOpt) {
    var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
    var logType = logTypeOpt !== undefined ? logTypeOpt : "INFO";
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : "USER_EVENT";
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    checkAndPushMissedEvents(eventName, paymentMethod);
    var eventNameStr = LoggerUtils$OrcaPaymentPage.eventNameToStrMapper(eventName);
    var firstEvent = Core__Option.isNone(events.contents[eventNameStr]);
    var latency$1 = latency !== undefined ? latency.toString() : calculateLatencyHook(eventName, undefined);
    var localTimestamp = Core__Option.getOr(timestamp, Date.now().toString());
    var localTimestampFloat = Core__Option.getOr(Core__Float.fromString(localTimestamp), Date.now());
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: sourceString,
          version: repoVersion,
          value: value,
          internalMetadata: internalMetadata,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: LoggerUtils$OrcaPaymentPage.getPaymentId(clientSecret$1.contents),
          appId: "",
          platform: window.navigator.platform,
          browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
          browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
          userAgent: window.navigator.userAgent,
          eventName: eventName,
          latency: latency$1,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents,
          ephemeralKey: ephemeralKey$1.contents
        });
    checkLogSizeAndSendData();
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setConfirmPaymentValue = function (paymentType) {
    return Object.fromEntries([
                [
                  "method",
                  "confirmPayment"
                ],
                [
                  "type",
                  paymentType
                ]
              ]);
  };
  var setLogApi = function (value, internalMetadata, eventName, timestamp, logTypeOpt, logCategoryOpt, paymentMethodOpt, apiLogTypeOpt, isPaymentSessionOpt) {
    var logType = logTypeOpt !== undefined ? logTypeOpt : "INFO";
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : "API";
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    var apiLogType = apiLogTypeOpt !== undefined ? apiLogTypeOpt : "Request";
    var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
    var eventNameStr = LoggerUtils$OrcaPaymentPage.eventNameToStrMapper(eventName);
    var firstEvent = Core__Option.isNone(events.contents[eventNameStr]);
    var latency = calculateLatencyHook(eventName, apiLogType);
    var localTimestamp = Core__Option.getOr(timestamp, Date.now().toString());
    var localTimestampFloat = Core__Option.getOr(Core__Float.fromString(localTimestamp), Date.now());
    var tmp;
    tmp = value.TAG === "ArrayType" ? JSON.stringify(Object.fromEntries(value._0)) : value._0;
    var tmp$1;
    tmp$1 = internalMetadata.TAG === "ArrayType" ? JSON.stringify(Object.fromEntries(internalMetadata._0)) : internalMetadata._0;
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: isPaymentSession ? "headless" : sourceString,
          version: repoVersion,
          value: tmp,
          internalMetadata: tmp$1,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: LoggerUtils$OrcaPaymentPage.getPaymentId(clientSecret$1.contents),
          appId: "",
          platform: window.navigator.platform,
          browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
          browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
          userAgent: window.navigator.userAgent,
          eventName: eventName,
          latency: latency,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents,
          ephemeralKey: ephemeralKey$1.contents
        });
    checkLogSizeAndSendData();
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setLogError = function (value, internalMetadataOpt, eventName, timestamp, latency, logTypeOpt, logCategoryOpt, paymentMethodOpt) {
    var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
    var logType = logTypeOpt !== undefined ? logTypeOpt : "ERROR";
    var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : "USER_ERROR";
    var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
    var eventNameStr = LoggerUtils$OrcaPaymentPage.eventNameToStrMapper(eventName);
    var firstEvent = Core__Option.isNone(events.contents[eventNameStr]);
    var latency$1 = latency !== undefined ? latency.toString() : calculateLatencyHook(eventName, undefined);
    var localTimestamp = Core__Option.getOr(timestamp, Date.now().toString());
    var localTimestampFloat = Core__Option.getOr(Core__Float.fromString(localTimestamp), Date.now());
    conditionalLogPush({
          timestamp: localTimestamp,
          logType: logType,
          category: logCategory,
          source: sourceString,
          version: repoVersion,
          value: value,
          internalMetadata: internalMetadata,
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: LoggerUtils$OrcaPaymentPage.getPaymentId(clientSecret$1.contents),
          appId: "",
          platform: window.navigator.platform,
          browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
          browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
          userAgent: window.navigator.userAgent,
          eventName: eventName,
          latency: latency$1,
          firstEvent: firstEvent,
          paymentMethod: paymentMethod,
          metadata: metadata$1.contents,
          ephemeralKey: ephemeralKey$1.contents
        });
    checkLogSizeAndSendData();
    events.contents[eventNameStr] = localTimestampFloat;
  };
  var setLogInitiated = function () {
    var eventNameStr = LoggerUtils$OrcaPaymentPage.eventNameToStrMapper("LOG_INITIATED");
    var firstEvent = Core__Option.isNone(events.contents[eventNameStr]);
    var latency = calculateLatencyHook("LOG_INITIATED", undefined);
    conditionalLogPush({
          timestamp: Date.now().toString(),
          logType: "INFO",
          category: "USER_EVENT",
          source: sourceString,
          version: repoVersion,
          value: "log initiated",
          internalMetadata: "",
          sessionId: sessionId$1.contents,
          merchantId: merchantId$1.contents,
          paymentId: LoggerUtils$OrcaPaymentPage.getPaymentId(clientSecret$1.contents),
          appId: "",
          platform: window.navigator.platform,
          browserName: Core__Option.getOr(arrayOfNameAndVersion[0], "Others"),
          browserVersion: Core__Option.getOr(arrayOfNameAndVersion[1], "0"),
          userAgent: window.navigator.userAgent,
          eventName: "LOG_INITIATED",
          latency: latency,
          firstEvent: firstEvent,
          paymentMethod: "",
          metadata: metadata$1.contents,
          ephemeralKey: ephemeralKey$1.contents
        });
    checkLogSizeAndSendData();
    events.contents[eventNameStr] = Date.now();
  };
  var handleBeforeUnload = function (_event) {
    sendLogs();
    var val = timeOut.contents;
    if (val !== undefined) {
      clearTimeout(Caml_option.valFromOption(val));
      return ;
    }
    
  };
  window.addEventListener("beforeunload", handleBeforeUnload);
  return {
          setLogInfo: setLogInfo,
          setLogError: setLogError,
          setLogApi: setLogApi,
          setLogInitiated: setLogInitiated,
          setConfirmPaymentValue: setConfirmPaymentValue,
          sendLogs: sendLogs,
          setSessionId: setSessionId,
          setClientSecret: setClientSecret,
          setMerchantId: setMerchantId,
          setMetadata: setMetadata,
          setSource: setSource,
          setEphemeralKey: setEphemeralKey
        };
}

export {
  logFileToObj ,
  getRefFromOption ,
  getSourceString ,
  findVersion ,
  browserDetect ,
  arrayOfNameAndVersion ,
  make ,
}
/* arrayOfNameAndVersion Not a pure module */
