// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Types$OrcaPaymentPage from "./Types.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Sentry$OrcaPaymentPage from "../hyper-log-catcher/Sentry.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "../Utilities/ApiEndpoint.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "../Utilities/LoggerUtils.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "../Utilities/EventListenerManager.bs.js";
import * as LoaderPaymentElement$OrcaPaymentPage from "./LoaderPaymentElement.bs.js";

function make(options, setIframeRef, ephemeralKey, sdkSessionId, publishableKey, logger, analyticsMetadata, customBackendUrl) {
  try {
    var iframeRef = [];
    var logger$1 = Core__Option.getOr(logger, LoggerUtils$OrcaPaymentPage.defaultLoggerConfig);
    var savedPaymentElement = {};
    var localOptions = Core__Option.getOr(Core__JSON.Decode.object(options), {});
    var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(publishableKey, undefined);
    var appearance = Core__Option.getOr(localOptions["appearance"], {});
    var launchTime = Utils$OrcaPaymentPage.getFloat(localOptions, "launchTime", 0.0);
    var fonts = Core__Option.getOr(Core__Option.flatMap(localOptions["fonts"], Core__JSON.Decode.array), []);
    var customPodUri = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(options), (function (x) {
                    return x["customPodUri"];
                  })), Core__JSON.Decode.string), "");
    var localSelectorString = "hyper-preMountLoader-iframe";
    var mountPreMountLoaderIframe = function () {
      if (document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString) == null) {
        var componentType = "preMountLoader";
        var iframeDivHtml = "<div id=\"orca-element-" + localSelectorString + "\" style= \"height: 0px; width: 0px; display: none;\"  class=\"" + componentType + "\">\n          <div id=\"orca-fullscreen-iframeRef-" + localSelectorString + "\"></div>\n           <iframe\n           id =\"orca-payment-element-iframeRef-" + localSelectorString + "\"\n           name=\"orca-payment-element-iframeRef-" + localSelectorString + "\"\n           title=\"Orca Payment Element Frame\"\n          src=\"" + ApiEndpoint$OrcaPaymentPage.sdkDomainUrl + "/index.html?fullscreenType=" + componentType + "&publishableKey=" + publishableKey + "&ephemeralKey=" + ephemeralKey + "&sessionId=" + sdkSessionId + "&endpoint=" + endpoint + "&hyperComponentName=" + Types$OrcaPaymentPage.getStrFromHyperComponentName("PaymentMethodsManagementElements") + "\"\n          allow=\"*\"\n          name=\"orca-payment\"\n          style=\"outline: none;\"\n        ></iframe>\n        </div>";
        var iframeDiv = document.createElement("div");
        iframeDiv.innerHTML = iframeDivHtml;
        document.body.appendChild(iframeDiv);
      }
      return document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString);
    };
    var locale = Utils$OrcaPaymentPage.getJsonStringFromDict(localOptions, "locale", "auto");
    var loader = Utils$OrcaPaymentPage.getJsonStringFromDict(localOptions, "loader", "");
    var preMountLoaderIframeDiv = mountPreMountLoaderIframe();
    var unMountPreMountLoaderIframe = function () {
      if (!(preMountLoaderIframeDiv == null)) {
        preMountLoaderIframeDiv.remove();
        return ;
      }
      
    };
    var preMountLoaderMountedPromise = new Promise((function (resolve, _reject) {
            var preMountLoaderIframeCallback = function (ev) {
              var json = ev.data;
              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
              if (Core__Option.isSome(dict["preMountLoaderIframeMountedCallback"])) {
                return resolve(true);
              } else if (Core__Option.isSome(dict["preMountLoaderIframeUnMount"])) {
                return unMountPreMountLoaderIframe();
              } else {
                return ;
              }
            };
            EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", preMountLoaderIframeCallback, "onPreMountLoaderIframeCallback");
          }));
    var fetchSavedPaymentMethods = function (mountedIframeRef, disableSaveCards, componentType) {
      return new Promise((function (resolve, param) {
                    if (disableSaveCards) {
                      resolve();
                    } else {
                      var handleSavedPaymentMethodsLoaded = function ($$event) {
                        var json = $$event.data;
                        var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                        var isSavedPaymentMethodsData = Utils$OrcaPaymentPage.getString(dict, "data", "") === "saved_payment_methods";
                        if (!isSavedPaymentMethodsData) {
                          return ;
                        }
                        resolve();
                        var json$1 = Utils$OrcaPaymentPage.getJsonFromDict(dict, "response", null);
                        var msg = Object.fromEntries([[
                                "savedPaymentMethods",
                                json$1
                              ]]);
                        Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                      };
                      EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleSavedPaymentMethodsLoaded, "onSavedPaymentMethodsLoaded-" + componentType);
                    }
                    var msg = Object.fromEntries([[
                            "sendSavedPaymentMethodsResponse",
                            !disableSaveCards
                          ]]);
                    Window$OrcaPaymentPage.iframePostMessage(preMountLoaderIframeDiv, msg);
                  }));
    };
    var setElementIframeRef = function (ref) {
      iframeRef.push(ref);
      setIframeRef(ref);
    };
    var getElement = function (componentName) {
      return savedPaymentElement[componentName];
    };
    var update = function (newOptions) {
      var newOptionsDict = Utils$OrcaPaymentPage.getDictFromJson(newOptions);
      var val = newOptionsDict["locale"];
      if (val !== undefined) {
        localOptions["locale"] = val;
      }
      var val$1 = newOptionsDict["appearance"];
      if (val$1 !== undefined) {
        localOptions["appearance"] = val$1;
      }
      iframeRef.forEach(function (iframe) {
            var message = Object.fromEntries([
                  [
                    "ElementsUpdate",
                    true
                  ],
                  [
                    "options",
                    newOptionsDict
                  ]
                ]);
            Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
    };
    var fetchUpdates = function () {
      return new Promise((function (resolve, param) {
                    setTimeout((function () {
                            resolve({});
                          }), 1000);
                  }));
    };
    var create = function (componentType, newOptions) {
      if (componentType === "") {
        ErrorUtils$OrcaPaymentPage.manageErrorWarning("REQUIRED_PARAMETER", "type", logger$1);
      }
      var otherElements = Utils$OrcaPaymentPage.isOtherElements(componentType);
      if (componentType !== "paymentMethodsManagement") {
        ErrorUtils$OrcaPaymentPage.manageErrorWarning("UNKNOWN_KEY", componentType + " type in create", logger$1);
      }
      var mountPostMessage = function (mountedIframeRef, selectorString, _sdkHandleOneClickConfirmPayment) {
        var widgetOptions = Utils$OrcaPaymentPage.getJsonFromArrayOfJson([
              [
                "ephemeralKey",
                ephemeralKey
              ],
              [
                "appearance",
                appearance
              ],
              [
                "locale",
                locale
              ],
              [
                "loader",
                loader
              ],
              [
                "fonts",
                fonts
              ]
            ]);
        var message = Object.fromEntries([
              [
                "paymentElementCreate",
                Utils$OrcaPaymentPage.getIsComponentTypeForPaymentElementCreate(componentType)
              ],
              [
                "otherElements",
                otherElements
              ],
              [
                "options",
                newOptions
              ],
              [
                "componentType",
                componentType
              ],
              [
                "paymentOptions",
                widgetOptions
              ],
              [
                "iframeId",
                selectorString
              ],
              [
                "publishableKey",
                publishableKey
              ],
              [
                "endpoint",
                endpoint
              ],
              [
                "sdkSessionId",
                sdkSessionId
              ],
              [
                "customPodUri",
                customPodUri
              ],
              [
                "parentURL",
                "*"
              ],
              [
                "analyticsMetadata",
                analyticsMetadata
              ],
              [
                "launchTime",
                launchTime
              ],
              [
                "customBackendUrl",
                customBackendUrl
              ]
            ]);
        Core__Promise.$$catch(preMountLoaderMountedPromise.then(function (param) {
                  var disableSavedPaymentMethods = Utils$OrcaPaymentPage.getBool(Utils$OrcaPaymentPage.getDictFromJson(newOptions), "displaySavedPaymentMethods", true);
                  if (disableSavedPaymentMethods && !Utils$OrcaPaymentPage.expressCheckoutComponents.includes(componentType)) {
                    Core__Promise.$$catch(fetchSavedPaymentMethods(mountedIframeRef, false, componentType).then(function () {
                              var msg = Object.fromEntries([[
                                      "cleanUpPreMountLoaderIframe",
                                      true
                                    ]]);
                              Window$OrcaPaymentPage.iframePostMessage(preMountLoaderIframeDiv, msg);
                              return Promise.resolve();
                            }), (function (param) {
                            return Promise.resolve();
                          }));
                  }
                  return Promise.resolve();
                }), (function (param) {
                return Promise.resolve();
              }));
        Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, message);
      };
      var paymentElement = LoaderPaymentElement$OrcaPaymentPage.make(componentType, newOptions, setElementIframeRef, iframeRef, mountPostMessage, true, RecoilAtoms$OrcaPaymentPage.defaultRedirectionFlags);
      savedPaymentElement[componentType] = paymentElement;
      return paymentElement;
    };
    return {
            getElement: getElement,
            update: update,
            fetchUpdates: fetchUpdates,
            create: create
          };
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    Sentry$OrcaPaymentPage.captureException(e);
    return Types$OrcaPaymentPage.defaultElement;
  }
}

export {
  make ,
}
/* Utils-OrcaPaymentPage Not a pure module */
