// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "../Utilities/ApiEndpoint.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "../Utilities/LoggerUtils.bs.js";
import * as PaymentSessionMethods$OrcaPaymentPage from "./PaymentSessionMethods.bs.js";

function make(options, clientSecret, publishableKey, logger, ephemeralKey, redirectionFlags) {
  var logger$1 = Core__Option.getOr(logger, LoggerUtils$OrcaPaymentPage.defaultLoggerConfig);
  var customPodUri = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(options), (function (x) {
                  return x["customPodUri"];
                })), Core__JSON.Decode.string), "");
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(publishableKey, undefined);
  return {
          getCustomerSavedPaymentMethods: (function () {
              return PaymentSessionMethods$OrcaPaymentPage.getCustomerSavedPaymentMethods(clientSecret, publishableKey, endpoint, logger$1, customPodUri, redirectionFlags);
            }),
          getPaymentManagementMethods: (function () {
              return PaymentSessionMethods$OrcaPaymentPage.getPaymentManagementMethods(ephemeralKey, logger$1, customPodUri, endpoint);
            })
        };
}

export {
  make ,
}
/* ApiEndpoint-OrcaPaymentPage Not a pure module */
