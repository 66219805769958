// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var context = React.createContext({
      paymentType: "Payment"
    });

var provider = context.Provider;

function usePaymentType() {
  return React.useContext(context).paymentType;
}

export {
  context ,
  provider ,
  usePaymentType ,
}
/* context Not a pure module */
