// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

function logApi(eventName, statusCodeOpt, dataOpt, apiLogType, urlOpt, paymentMethodOpt, resultOpt, optLogger, logTypeOpt, logCategoryOpt, isPaymentSessionOpt) {
  var statusCode = statusCodeOpt !== undefined ? statusCodeOpt : "";
  var data = dataOpt !== undefined ? dataOpt : ({});
  var url = urlOpt !== undefined ? urlOpt : "";
  var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
  var result = resultOpt !== undefined ? resultOpt : ({});
  var logType = logTypeOpt !== undefined ? logTypeOpt : "INFO";
  var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : "API";
  var isPaymentSession = isPaymentSessionOpt !== undefined ? isPaymentSessionOpt : false;
  var match;
  switch (apiLogType) {
    case "Request" :
        match = [
          [[
              "url",
              url
            ]],
          []
        ];
        break;
    case "Response" :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              statusCode
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    case "NoResponse" :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              "504"
            ],
            [
              "response",
              data
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    case "Method" :
        match = [
          [[
              "method",
              paymentMethod
            ]],
          [[
              "result",
              result
            ]]
        ];
        break;
    case "Err" :
        match = [
          [
            [
              "url",
              url
            ],
            [
              "statusCode",
              statusCode
            ],
            [
              "response",
              data
            ]
          ],
          [[
              "response",
              data
            ]]
        ];
        break;
    
  }
  if (optLogger !== undefined) {
    return optLogger.setLogApi({
                TAG: "ArrayType",
                _0: match[0]
              }, {
                TAG: "ArrayType",
                _0: match[1]
              }, eventName, undefined, logType, logCategory, undefined, apiLogType, isPaymentSession);
  }
  
}

function logInputChangeInfo(text, logger) {
  logger.setLogInfo(text, undefined, "INPUT_FIELD_CHANGED", undefined, undefined, undefined, undefined, undefined);
}

function handleLogging(optLogger, value, internalMetadataOpt, eventName, paymentMethod, logTypeOpt) {
  var internalMetadata = internalMetadataOpt !== undefined ? internalMetadataOpt : "";
  var logType = logTypeOpt !== undefined ? logTypeOpt : "INFO";
  if (optLogger !== undefined) {
    return optLogger.setLogInfo(value, internalMetadata, eventName, undefined, undefined, logType, undefined, paymentMethod);
  }
  
}

function eventNameToStrMapper(eventName) {
  return eventName;
}

function getPaymentId(clientSecret) {
  return Core__Option.getOr(clientSecret.split("_secret_")[0], "");
}

function convertToScreamingSnakeCase(text) {
  return text.trim().replace(/ /g, "_").toUpperCase();
}

function toSnakeCaseWithSeparator(str, separator) {
  return str.replace(/[A-Z]/g, (function (letter, param, param$1) {
                return separator + letter.toLowerCase();
              }));
}

function defaultLoggerConfig_setLogInfo(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  
}

function defaultLoggerConfig_setLogError(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  
}

function defaultLoggerConfig_setLogApi(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  
}

function defaultLoggerConfig_setLogInitiated() {
  
}

function defaultLoggerConfig_setConfirmPaymentValue(param) {
  return {};
}

function defaultLoggerConfig_sendLogs() {
  
}

function defaultLoggerConfig_setSessionId(_x) {
  
}

function defaultLoggerConfig_setClientSecret(_x) {
  
}

function defaultLoggerConfig_setMerchantId(_x) {
  
}

function defaultLoggerConfig_setMetadata(_x) {
  
}

function defaultLoggerConfig_setSource(_x) {
  
}

function defaultLoggerConfig_setEphemeralKey(_x) {
  
}

var defaultLoggerConfig = {
  setLogInfo: defaultLoggerConfig_setLogInfo,
  setLogError: defaultLoggerConfig_setLogError,
  setLogApi: defaultLoggerConfig_setLogApi,
  setLogInitiated: defaultLoggerConfig_setLogInitiated,
  setConfirmPaymentValue: defaultLoggerConfig_setConfirmPaymentValue,
  sendLogs: defaultLoggerConfig_sendLogs,
  setSessionId: defaultLoggerConfig_setSessionId,
  setClientSecret: defaultLoggerConfig_setClientSecret,
  setMerchantId: defaultLoggerConfig_setMerchantId,
  setMetadata: defaultLoggerConfig_setMetadata,
  setSource: defaultLoggerConfig_setSource,
  setEphemeralKey: defaultLoggerConfig_setEphemeralKey
};

export {
  logApi ,
  logInputChangeInfo ,
  handleLogging ,
  eventNameToStrMapper ,
  getPaymentId ,
  convertToScreamingSnakeCase ,
  toSnakeCaseWithSeparator ,
  defaultLoggerConfig ,
}
/* No side effect */
